/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { links } from "../../helper/constants";
import { windowOverflowDefault, windowOverflowHidden } from "../../helper/functions";
import { event } from "../../lib/gtag";

import styles from "./styles.module.scss";
import CloseIcon from "../../public/assets/icons/close-white.svg";
import EvShowEventPopupImage from "../../public/assets/images/ev-show-event-popup.png";
import { getWithExpiry, setWithExpiry } from "../../helper/timeout_storage";

const ONE_WEEK_IN_HOURS = 168;

export default function EVShowEventPopup() {
	const [display, setDisplay] = useState(false);
	const modalPopupRef = useRef<HTMLDivElement>(null);
	const imageContainerRef = useRef<HTMLDivElement>(null);

	// Displaying popup after 2 seconds / show once a week only
	useEffect(() => {
		setTimeout(() => {
			const seenEvShowPopup = getWithExpiry("seenEvShowPopup");

			if (!seenEvShowPopup) {
				event("evShowPopupViewedHomePage");
				setDisplay(true);
				setWithExpiry("seenEvShowPopup", "true", ONE_WEEK_IN_HOURS);
			}
		}, 2000);
	}, []);

	// Managing Window Overflow
	useEffect(() => {
		if (display) windowOverflowHidden();
		else windowOverflowDefault();

		return () => windowOverflowDefault();
	}, [display]);

	// Managing container resize, image ratio 1:1
	useEffect(() => {
		if (!imageContainerRef || !imageContainerRef.current) return;

		const onResize = () => {
			if (!imageContainerRef || !imageContainerRef.current) return;

			const imageContainer = imageContainerRef.current;

			imageContainer.style.width = "";
			imageContainer.style.height = "";

			const width = imageContainer.offsetWidth;
			const height = imageContainer.offsetHeight;

			const newWidthHeight = Math.min(width, height);

			imageContainer.style.width = `${newWidthHeight}px`;
			imageContainer.style.height = `${newWidthHeight}px`;
		};

		onResize();

		window.addEventListener("resize", onResize);

		return () => window.removeEventListener("resize", onResize);
	}, [imageContainerRef]);

	const hidePopup = (evt: any) => {
		evt.preventDefault();
		evt.stopPropagation();

		if (modalPopupRef && modalPopupRef.current) {
			event("evShowPopupClosedHomePage");

			modalPopupRef.current.classList.toggle(styles.modalPopupDisappear);

			setTimeout(() => {
				setDisplay(false);
			}, 500);
		}
	};

	if (!display) return null;

	return (
		<div className={styles.modalPopup} ref={modalPopupRef}>
			<div onClick={hidePopup} className={`blurry-cover ${styles.cover}`}>
				&nbsp;
			</div>
			<div className={styles.modalContent} ref={imageContainerRef}>
				<div className={styles.closeHolder}>
					<img className={styles.closeIcon} src={CloseIcon.src} alt="close icon" onClick={hidePopup} />
				</div>

				<Link href={links.ev_talk_show_page} passHref>
					<img className={styles.popupImage} src={EvShowEventPopupImage.src} alt="Electric Vehicle Show, 17 Feb 2024. RDS, Dublin." onClick={() => event("evShowPopupClickedHomePage")} />
				</Link>
			</div>
		</div>
	);
}
