
/**
 * @description Set an item in local storage with an expiry time specified in hours
 * @param key key of the item 
 * @param value value of the item
 * @param hours number of hours to expire
 */
export function setWithExpiry(key: string, value: any, hours: number) {
	const now = new Date();

	const ttl = now.getTime() + (hours * 60 * 60 * 1000);

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: ttl,
	}

	localStorage.setItem(key, JSON.stringify(item))
}

/**
 * @description Get an item from local storage if it exists and is not expired
 * @param key key of the item
 * @returns value of the item if it exists, else null
 */
export function getWithExpiry(key: string) {
	const itemStr = localStorage.getItem(key)
	
  // if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}

	const item = JSON.parse(itemStr)

	const now = new Date()

	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key)
		return null
	}

	return item.value
}